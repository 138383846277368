class Modal {

    constructor(attrs) {
        this.modal = null;
        this.modalContent = null;
        this.modalBackground = null;
        this.btnClose = null;
        this.modalBackgroundColor = "rgba(10,10,10,.4)";
        this.modalId = '';
        this.size = '';
        this.overflow = 'auto';
        this.onClose = null;
        this.hideCloseButton = false;
        this.preserveDomOnDismiss = false;
        addProperty(this, attrs);
        if (!oid(this.modalId)) {
            this.createElements();
        } else {
            this.findExistingModal();
        }
        this.modalContent.style.overflow = this.overflow;
    }

    setContentHTML(content) {
        this.modalContent.innerHTML = content;
        this.bindContent();
    }

    setContentDom(dom) {
        this.modalContent.innerHTML = '';
        this.modalContent.append(dom);
        this.bindContent();
    }

    loadContentFromUrl(url, then, params = null) {
        const self = this;
        this.startLoad();
        return ajx(url, params === null ? 'GET' : 'POST', params).then((html) => {
            this.endLoad();
            self.setContentHTML(html);
            if (then) then();
            return self.modalContent;
        });
    }

    createElements() {
        const self = this;

        this.modal = document.createElement('div');
        this.modal.className = "modal " + this.size;
        if (this.modalId.length > 0) {
            this.modal.id = this.modalId;
        }

        this.btnClose = document.createElement('span');
        this.btnClose.innerHTML = "&times;";
        this.btnClose.className = "modal-close";
        this.btnClose.id = this.modalId + "-close";

        this.modalContent = document.createElement('div');
        this.modalContent.className = "modal-content";
        this.modalContent.id = this.modalId + "-content";

        this.modalBackground = document.createElement('div');
        this.modalBackground.className = "modal-background";
        this.modalBackground.id = "-background";
        this.modalBackground.style.backgroundColor = this.modalBackgroundColor;

        this.modal.appendChild(this.modalBackground);
        if (!this.hideCloseButton) {
            this.modal.appendChild(this.btnClose);
        }
        this.modal.appendChild(this.modalContent);
        document.body.appendChild(this.modal);
    }

    bindDismissListener() {
        if(this.modalBackground){
            this.modalBackground.onclick = () => {
                if (this.onClose) {
                    this.onClose(this);
                }
                this.dismiss();
            };
        }

        if (this.btnClose) {
            this.btnClose.onclick = () => {
                if (this.onClose) {
                    this.onClose(this);
                }
                this.dismiss();
            };
        }

        const dismissButtons = this.modal.querySelectorAll('[data-event="dismiss"]');
        if (dismissButtons) {
            dismissButtons.forEach(btn => {
                btn.addEventListener('click', () => {
                    if (this.onClose) {
                        this.onClose(this);
                    }
                    this.dismiss()
                }, true);
            });
        }

        this.dismissOnEchapListener = (e) => {
            if (pressEchap(e) && !["INPUT", "SELECT"].includes(e.target.tagName)) {
                if (this.onClose) {
                    this.onClose(this);
                }
                this.dismiss();
            }
            e.stopPropagation();
        };
        document.addEventListener('keyup', this.dismissOnEchapListener, true);
    }

    isVisible(){
        return this.modal.classList.contains('active');
    }

    show() {
        this.bindDismissListener();
        this.modal.classList.add('active');
        setTimeout(() => {
            this.modal.classList.add('pop');
        }, 150);
    }

    dismiss() {
        if (this.modal.id === this.modalId) {
            this.modal.classList.remove('pop');
            setTimeout(() => {
                this.modal.classList.remove('active');
                if (this.modal.parentNode && !this.preserveDomOnDismiss) {
                    this.modal.parentNode.removeChild(this.modal);
                }
            }, 150);
        }
        document.removeEventListener('keyup', this.dismissOnEchapListener, true);
    }

    /**
     * Animation de chargement
     */
    startLoad() {
        Ui.circleLoader(this.modalContent);
        this.modalContent.classList.add('loading');
    }

    endLoad() {
        this.modalContent.classList.remove('loading');
    }

    modalPost(form, callback, url, submitButton) {
        const self = this;

        if (typeof url === "undefined" || url === null) {
            url = form.getAttribute('action');
        }

        if (this.modalBackground) {
            this.modalBackground.onclick = function () {
                self.dismiss();
                callback(null);
            };
        }

        this.dismissOnEchapListener = (e) => {
            if (pressEchap(e)) {
                this.dismiss();
                callback(null);
            }
            e.stopPropagation();
        };

        foreach(form.getElementsByClassName('btn'), function (btn) {
            if (btn.getAttribute('data-event') === 'dismiss') {
                btn.onclick = function (e) {
                    e.preventDefault();
                    self.dismiss();
                    callback(null);
                }
            } else if (btn.getAttribute('type') === 'submit' && typeof submitButton === "undefined") {
                submitButton = btn;
            }
        });

        setFormPostListener(form, callback, url, submitButton);
    }

    bindContent() {
        const self = this;
        foreach(this.modal.querySelectorAll("form.form-ajax"), form => {
            setFormPostListener(form, (response) => {
                handleJsonResp(response);
                self.dismiss();
                document.location.reload();
            });
        });
    }

    findExistingModal() {
        this.modal = oid(this.modalId);
        this.modalContent = oid(this.modalId + '-content');
        this.modalBackground = oid(this.modalId + '-background');
        this.btnClose = oid(this.modalId + '-close');
    }
}

module.exports = Modal;
global.Modal = Modal;
